.common-table {
    flex: 1;

    .ant-table {

        .ant-table-thead {
            background-color: #FAFAFA;
            padding: 0;
            height: 56px;

            .ant-table-cell {
                background-color: #FAFAFA;
                border-bottom: none;


                color: rgba(0, 0, 0, 0.85);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:first-child {
                    border-start-start-radius: 0px;
                }

                &:last-child {
                    border-start-end-radius: 0px;
                }
            }

            th {
                font-weight: 400;
                border-radius: 0;
            }


        }
    }



    &-ope {
        cursor: pointer;
        display: flex;
        align-items: center;

        &-finish {
            cursor: pointer;
            border-radius: 2px;
            border: 1px solid #1890FF;
            background: #1890FF;

            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--character-primary-inverse, #FFF);
            text-align: center;

            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
    }

    .common-table-action {
        cursor: pointer;
        display: flex;
        color: #3E7BFA;
        gap: 8px;

        /* Body/regular */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;