.user-panel {
    display: flex;
    flex-direction: column;
    // padding: 21px;
    // gap: 15px;

    &-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        // padding: 8px 16px;
        gap: 16px;

    }
}

.user-ope-area {
    display: flex;
    padding: 8px 16px;
    border-bottom: 1px solid #D3D3D3;

    &-left {
        cursor: pointer;
        display: inline-flex;
        padding: 4px 10px;
        justify-content: center;
        border-radius: 100px;
        border: 1px solid #3E7BFA;
        background: #3E7BFA;

        color: #FFF;
        text-align: center;

        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    }

    &-right {
        display: flex;
        margin-left: 120px;
        align-items: center;
        gap: 9px;

        &-tip {
            min-width: 36px;
            width: fit-content;
            color: rgba(0, 0, 0, 0.85);
            text-align: center;

            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        &-btn {
            cursor: pointer;
            display: inline-flex;
            padding: 4px 10px;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            border: 1px solid #D9D9D9;
            background: #FFF;

            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
        }
    }
}

.user-table {
    flex: 1;

    &-ope {
        cursor: pointer;
        display: flex;
        align-items: center;

        &-finish {
            cursor: pointer;
            border-radius: 2px;
            border: 1px solid #1890FF;
            background: #1890FF;

            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--character-primary-inverse, #FFF);
            text-align: center;

            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
    }

    .user-table-action {
        cursor: pointer;
        display: flex;
        color: #3E7BFA;
        gap: 8px;

        /* Body/regular */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
    }
}

.user-modal {

    .ant-modal-body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .user-modal-row {
            display: flex;

            &-text {
                width: 100px;
                text-align: right;

                &::after {
                    content: ":";
                    margin: 0 8px 0 2px;
                    position: relative;
                    top: -.5px;
                }
            }

            &-value {
                flex: 1;
            }
        }
    }

    .ant-upload-picture-card-wrapper {
        width: 102px;
        height: 102px;
    }

    .ant-form-item-label {
        width: 50px;
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;