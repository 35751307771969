.after-sale-pannel {
    display: flex;
    // padding: 21px;
    overflow: hidden;


    .after-sale-pannel-table {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .after-sale-table {
            flex: 1;

            .ant-spin-nested-loading {
                height: 100%;

                .ant-spin-container {
                    height: 100%;
                }
            }

            .ant-pagination {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            .after-sale-status-doing {
                color: #000;
            }

            .after-sale-status-done {
                color: #8E8E8E;
            }

            .after-sale-ope-btn {
                display: flex;

                cursor: pointer;
                display: flex;
                color: #3E7BFA;
                gap: 8px;

                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }
        }
    }
}

.after-sale-ope-type-tip {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;