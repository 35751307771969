.edit-merchant-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    overflow: auto;
    padding: 0 21px;

    .edit-merchant-title {
        display: flex;
        padding: 8px 0px;
        gap: 8px;
        border-bottom: 1px solid #D3D3D3;
        height: 49px;
        flex-shrink: 0;
        margin-bottom: 12px;

        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }

    .edit-merchant-panel {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1 1 auto;
        overflow: auto;

        .submit-btn {
            display: flex;
            padding: 4px 15px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 1px solid #1890FF;
            background: #1890FF;
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        .cancel-btn {
            display: flex;
            padding: 4px 15px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;