.edit-sku-panel {
    flex: 1;
    display: flex;
    padding: 21px;

    overflow: hidden;

    .edit-sku-col-part {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        // overflow-y: auto;
        overflow: hidden;
        padding: 0;

        &-title {
            color: rgba(0, 0, 0, 0.85);
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
        }

        &-content {

            overflow: auto;

            .ant-upload-picture-card-wrapper {
                width: 102px;
                height: 102px;
            }

            .edit-sku-form {
                padding-right: 120px;
            }
        }
    }

    .edit-sku-col-divide {
        height: 100%;
        width: 1px;
        background-color: #E4E6ED;
        margin: 0 10px;
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;