.order-panel {
    display: flex;
    flex-direction: column;
    // padding: 21px;
    gap: 15px;
    flex: 1;
    overflow: auto;

    &-title {
        display: flex;
        padding: 8px 16px;
        gap: 8px;
        border-bottom: 1px solid #D3D3D3;
        height: 49px;
        flex-shrink: 0;

        &-left {
            display: flex;
            gap: 31px;

            &-row {
                display: flex;
                gap: 8px;
                align-items: center;

                &-title {
                    flex-shrink: 0;
                    color: #86909C;
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }

        &-right {
            margin-left: auto;
            display: flex;
            gap: 12px;

            .sku-pannel-title-btn {
                cursor: pointer;
                display: inline-flex;
                padding: 4px 10px;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                border: 1px solid #3E7BFA;
                background: #3E7BFA;

                box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
                color: #FFF;
                text-align: center;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;

                &-cancel {
                    color: #000;
                    border: 1px solid #D9D9D9;
                    background: #FFF;
                    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
                }
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;

        .order-status-close {
            color: #8E8E8E;
        }

        .order-status-doing {
            color: #000;
        }

        // .order-table {
        //     flex: 1;

        //     .order-ope-btn {
        //         display: flex;
        //         justify-content: flex-end;

        //         cursor: pointer;
        //         display: flex;
        //         color: #3E7BFA;
        //         gap: 8px;

        //         font-family: Roboto;
        //         font-size: 14px;
        //         font-style: normal;
        //         font-weight: 400;
        //         line-height: 22px;
        //     }
        // }

        .ant-spin-nested-loading {
            height: 100%;

            .ant-spin-container {
                height: 100%;
            }
        }

        .ant-pagination {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &-pagi {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 16px 20px;
    }

    &-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 32px;
        background-color: red;
    }
}

.order-detail-modal {

    .ant-modal-header {
        color: rgba(0, 0, 0, 0.85);

        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .ant-modal-body {
        padding: 0;

        max-height: 600px;
        overflow: hidden;
        overflow-y: auto;
    }

    .order-detail-modal-list {
        display: flex;
        flex-direction: column;


        .order-detail-modal-row {
            padding: 12px 24px;

            display: flex;
            color: #262626;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

            background: #FFF;
            box-shadow: 0px -1px 0px 0px #F0F0F0 inset;

            &-name {
                width: 120px;
                color: #8E8E8E;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;

                &::after {
                    content: "：";
                }
            }

            &-value {
                flex: 1;
                display: flex;
                align-items: center;
                word-wrap: break-word;
                word-break: break-all;

                .logistics-number-input {
                    display: flex;
                    padding: 0px 6px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: #F2F2F2;
                }
            }

            &-list {
                align-items: unset;
                flex-direction: column;
                gap: 8px;

                &-item {
                    display: flex;
                    gap: 16px;

                    .order-detail-sku-content {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        .order-detail-sku-row {
                            display: flex;
                            gap: 8px;

                            &-label {
                                color: #8E8E8E;
                                font-family: "PingFang SC";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                flex-shrink: 0;

                                &-value {
                                    color: #000;
                                    font-family: "PingFang SC";
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.logistics-modal {
    .ant-modal-body {
        height: 138px;
        padding: 0;
    }

    .logistics-modal-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;

        &-default {
            cursor: pointer;
            padding: 5px 16px;

            color: #FFF;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            border-radius: 2px;
            background: #165DFF;
        }

        &-cancel {
            color: #4E5969;
            border-radius: 2px;
            background: #F2F3F5;
        }
    }

    &-form-row {
        display: flex;
        padding: 12px 24px;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        box-shadow: 0px -1px 0px 0px #F0F0F0 inset;

        &-label {
            width: 62px;
            color: #8E8E8E;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        &-value {
            flex: 1;

            .ant-select {
                width: 100%;
            }
        }
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;