.order-edit-panel {

    .order-edit-list {
        display: flex;
        flex-direction: column;


        .order-edit-row {
            padding: 12px 24px;

            display: flex;
            color: #262626;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

            background: #FFF;
            box-shadow: 0px -1px 0px 0px #F0F0F0 inset;

            &-name {
                width: 120px;
                color: #8E8E8E;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;

                &::after {
                    content: "：";
                }
            }

            &-value {
                flex: 1;
                display: flex;
                align-items: center;
                word-wrap: break-word;
                word-break: break-all;

                .logistics-number-input {
                    display: flex;
                    padding: 0px 6px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: #F2F2F2;
                }
            }

            &-list {
                align-items: unset;
                flex-direction: column;
                gap: 8px;

                &-item {
                    display: flex;
                    gap: 16px;

                    .order-detail-sku-content {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        .order-detail-sku-row {
                            display: flex;
                            gap: 8px;

                            &-label {
                                color: #8E8E8E;
                                font-family: "PingFang SC";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                flex-shrink: 0;

                                &-value {
                                    color: #000;
                                    font-family: "PingFang SC";
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;