.login-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: linear-gradient(215deg, #FFF 50.17%, #C9DDFF 97.05%);
    overflow: hidden;
    position: relative;

    .big-bg {
        position: absolute;
        top: 50%;
        left: 144px;
        width: 45%;
        height: 67%;
        flex-shrink: 0;
        transform: translateY(-50%);
        // background-image: url("../../../public/login.png");
        background-size: 100% 100%;
    }

    .login-panel {
        position: absolute;
        top: 50%;
        right: 278px;
        width: 420px;
        height: 388px;
        transform: translateY(-50%);

        display: flex;
        padding: 80px 60px;
        flex-direction: column;
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);

        .welcome {
            color: #FFF;
            font-family: "Microsoft YaHei UI";
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin-bottom: 60px;
        }

        .ant-input-affix-wrapper {
            height: 40px;
            border-radius: 3px;
            border: 1px solid #DCDCDC;
            padding: 5px 8px;

            .ant-input-prefix {
                margin-inline-end: 8px !important;
            }

            .ant-input {
                text-overflow: ellipsis;
                white-space: nowrap;
                font-family: "Microsoft YaHei UI";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }
        }

        .remeber-me {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
            cursor: pointer;

            svg {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 8px;
            }
        }

        .ant-btn {
            width: 100%;
            height: 40px;
            border-radius: 5px;
            background: #2469EF;
            color: #FFF;
            font-family: "Microsoft YaHei UI";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-top: auto;

            &:hover {
                color: #FFF;
                background: #2469EF;
            }
        }
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;