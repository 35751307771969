.sku-panel {
    display: flex;
    flex-direction: column;
    // padding: 21px;
    gap: 15px;
    flex: 1;

    &-title {
        display: flex;
        padding: 8px 16px;
        gap: 8px;
        border-bottom: 1px solid #D3D3D3;
        height: 49px;
        flex-shrink: 0;

        &-right {
            margin-left: auto;
            display: flex;
            gap: 12px;

            .sku-pannel-title-btn {
                cursor: pointer;
                display: inline-flex;
                padding: 4px 10px;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                border: 1px solid #3E7BFA;
                background: #3E7BFA;

                box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
                color: #FFF;
                text-align: center;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;

                &-cancel {
                    color: #000;
                    border: 1px solid #D9D9D9;
                    background: #FFF;
                    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
                }
            }
        }
    }

    &-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: hidden;

        &-table {
            flex: 1;
            overflow-y: auto;
        }
    }
}

.sku-ope-area {
    display: flex;

    &-left {
        cursor: pointer;
        display: inline-flex;
        padding: 4px 10px;
        justify-content: center;
        border-radius: 100px;
        border: 1px solid #3E7BFA;
        background: #3E7BFA;

        color: #FFF;
        text-align: center;

        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    }

    &-right {
        display: flex;
        margin-left: 120px;
        align-items: center;
        gap: 9px;

        &-tip {
            min-width: 36px;
            width: fit-content;
            color: rgba(0, 0, 0, 0.85);
            text-align: center;

            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        &-btn {
            cursor: pointer;
            display: inline-flex;
            padding: 4px 10px;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            border: 1px solid #D9D9D9;
            background: #FFF;

            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
        }
    }
}

// .sku-table {
//     flex: 1;

//     .ant-table {

//         .ant-table-thead {
//             background-color: #FAFAFA;
//             padding: 0;
//             height: 56px;

//             .ant-table-cell {
//                 background-color: #FAFAFA;
//                 border-bottom: none;


//                 color: rgba(0, 0, 0, 0.85);
//                 font-family: Roboto;
//                 font-size: 14px;
//                 font-style: normal;
//                 font-weight: 500;
//                 line-height: 22px;

//                 white-space: nowrap;
//                 overflow: hidden;
//                 text-overflow: ellipsis;

//                 &:first-child {
//                     border-start-start-radius: 0px;
//                 }

//                 &:last-child {
//                     border-start-end-radius: 0px;
//                 }
//             }

//             th {
//                 font-weight: 400;
//                 border-radius: 0;
//             }


//         }
//     }



//     &-ope {
//         cursor: pointer;
//         display: flex;
//         align-items: center;

//         &-finish {
//             cursor: pointer;
//             border-radius: 2px;
//             border: 1px solid #1890FF;
//             background: #1890FF;

//             box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             color: var(--character-primary-inverse, #FFF);
//             text-align: center;

//             font-family: Roboto;
//             font-size: 14px;
//             font-style: normal;
//             font-weight: 400;
//             line-height: 22px;
//         }
//     }

//     .sku-table-action {
//         cursor: pointer;
//         display: flex;
//         color: #3E7BFA;
//         gap: 8px;

//         /* Body/regular */
//         font-family: Roboto;
//         font-size: 14px;
//         font-style: normal;
//         font-weight: 400;
//         line-height: 22px;
//         /* 157.143% */
//     }
// }

.sku-modal {

    .ant-modal-body {
        max-height: 600px;
        overflow: hidden;
        overflow-y: auto;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .ant-upload-picture-card-wrapper {
        width: 102px;
        height: 102px;
    }

    .ant-form-item-label {
        width: 100px;
    }

    // .sku-form {}
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;