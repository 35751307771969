.sale-statistic-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    background-color: #FFF;
    overflow: hidden;

    .statistic-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 24px;
        gap: 10px;
        border-radius: 8px;
        background: #F7F8FA;

        .statistic-data-info {
            display: flex;
            color: #000;
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .statistic-data-row {
            display: flex;
            flex-direction: row;
            gap: 20px;

            .statistic-data-block {
                display: flex;
                flex-direction: column;
                width: 310px;
                padding: 26px 11px;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                flex-shrink: 0;
                border-radius: 8px;
                background: #FFF;

                .statistic-data-first {
                    color: #000;
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.64px;
                }

                .statistic-data-second {
                    color: #000;
                    font-family: "PingFang SC";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.96px;
                }

                .statistic-data-third {
                    display: flex;
                    gap: 65px;

                    span {
                        color: #888;
                        font-family: "PingFang SC";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.64px;
                    }
                }
            }
        }
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;