.tn-modal-panel {

    .ant-modal-content{
        padding: 0;
    }

    .ant-modal-body {
        padding: 16px 16px 24px 16px;
    }


    .ant-modal-confirm-body {

        .ant-modal-confirm-title {
            color: #333;
            font-family: "Hiragino Sans GB";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .ant-modal-confirm-content {
            margin-top: 16px;
            color: #333;
            font-family: "Hiragino Sans GB";
            font-size: 16px;
            font-style: normal;
            // font-weight: 300;
            line-height: normal;
        }
    }

    .ant-modal-confirm-btns {
        margin-top: 16px;
        .modal-footer-button {
            width: 64px;
            height: 28px;
            border-radius: 4px;
            border: none;
            padding-top: 3px;
            padding-bottom: 4px;
            color: #FFF;
            font-family: "Hiragino Sans GB";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            &.primary {
                background-color: #1B439A;
            }

            &.second {
                background-color: #AFAFAF;
            }

            &.danger {
                background-color: #F00505;
            }
        }
    }

    &.closable-hidden {
        .ant-modal-close {
            display: none;
        }
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;