.main-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;

    .backend-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 72px;
        flex-shrink: 0;
        background: #FFF;
        padding-right: 24px;

        .left-logo-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 226px;
            height: 72px;
            cursor: pointer;
            gap: 9px;
            flex-shrink: 0;
            border-right: 1px solid #DCDFE6;

            .big-text {
                color: #303133;
                font-family: "DingTalk JinBuTi";
                font-size: 22.125px;
                font-style: normal;
                font-weight: 400;
                line-height: 30.975px;
            }

            .small-icon {
                display: flex;
                width: 30px;
                height: 30px;
                background-image: url("../../public/avatar.png");
                flex-shrink: 0;
            }
        }

        .user-group {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            // width: 160px;
            height: 72px;
            margin-left: auto;
            gap: 8px;

            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-text {
                color: #262626;
                font-family: "PingFang SC";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 72px;
            }
        }
    }
    .corner-icon{
        background: red;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        position: absolute; 
        top: 12px;
        right:126px;         
    }

    .backend-container {
        display: flex;
        height: 100%;
        // background-color: aqua;
        flex: 1 1 auto;
        overflow: hidden;

        .left-menu {
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            width: 226px;
            overflow: auto;
            background-color: #FFF;
            align-items: center;
            overflow-x: hidden;

            .left-logo-group {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: left;
                margin-left: 19%;
                width: 226px;
                height: 72px;
                cursor: pointer;
                gap: 9px;
                flex-shrink: 0;
                border-bottom: 1px solid #DCDFE6;

                .big-text {
                    color: #303133;
                    font-family: "DingTalk JinBuTi";
                    font-size: 22.125px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30.975px;
                }

                .small-icon {
                    display: flex;
                    width: 30px;
                    height: 30px;
                    background-image: url("../../public/avatar.png");
                    flex-shrink: 0;
                }
            }

            .option {
                cursor: pointer;
                padding: 18px 24px;
                display: flex;
                align-items: center;
                width: 100%;
                gap: 8px;
                position: relative;


                .right-text {
                    color: #606266;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &:hover {
                        color: #131414;
                    }
                }

                &.selected {
                    // border-right: 3px solid #409EFF;
                    background: #D9ECFF;

                    .right-text {
                        color: #3E7BFA !important;
                    }
                }
            }

            .user-group {
                margin-top: auto;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                height: 72px;
                margin-left: 14%;
                gap: 8px;

                &-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &-text {
                    color: #262626;
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 72px;
                }
            }
        }

        .right-panel {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F5F5F5;
            flex: 1;
            padding: 16px;

            .main-route {
                display: flex;
                flex: 1 1 auto;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                background: #FFF;
            }
        }
    }
}

@media screen and (max-width: 1160px) {
    .main-content {
        padding-right: 10px;
        padding-left: 10px;
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;