.merchant-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px;
    overflow-y: auto;
    padding-bottom: 24px;
    gap: 16px;

    .banner {
        border-radius: 8px;
        background: #F7F8FA;
        // margin: 20px;
        display: flex;
        align-items: center;
        // justify-content: center;
        width: 100%;
        height: 180px;

        figure {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: black;
            font-size: 16px;
            padding-right: 16px;
        }

        table {
            td {
                max-width: 700px;
                padding-right: 14px;

                .title {
                    padding-top: 0;
                    color: #6F6B66;
                    font-size: 16px;
                    word-wrap: break-word
                }
            }
        }

        button {
            border: 0;
            border-radius: 100px;
            background-color: #1890Ff;
            // margin: auto 150px;
            color: white;
            font-size: 14px;
        }
    }


    .bg-area {
        display: flex;
        width: 100%;
        height: 320px;
        // background-image: url("../../../public/shop-background.png");
        // background-size: 100% 100%;
        flex-shrink: 0;

        .bg-item {
            width: 100%;
            height: 320px;
            background-image: url("../../../public/shop-background.png");
            background-size: 100% 100%;
            flex-shrink: 0;
        }
        .bg-item2 {
            width: 100%;
            height: 320px;
            background-image: url("../../../public/shop-background2.png");
            background-size: 100% 100%;
            flex-shrink: 0;
        }
        .bg-item3 {
            width: 100%;
            height: 320px;
            background-image: url("../../../public/shop-background3.png");
            background-size: 100% 100%;
            flex-shrink: 0;
        }
        
    }

    .shop-block-area {
        position: relative;
        display: flex;
        align-items: end;
        width: 100%;
        // height: 150px;
        background-color: #f7f8fa;
        border-radius: 8px;
        padding: 8px 24px;

        .shop-block {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 100%;
            // height: 150px;
            // padding: 24px 168px 24px 24px;
            gap: 40px;

            .divider {
                display: flex;
                flex-shrink: 0;
                width: 1px;
                height: 100%;
                background-color: #E4E6ED;

            }

            .logo-area {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // width: 74px;
                height: 100%;
                flex-shrink: 0;
                color: #000;
            }

            .pic-area {
                display: flex;
                flex-direction: column;
                width: 80px;
                flex-shrink: 0;

                .pic-info {
                    color: #000;
                    font-family: "PingFang SC";
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0.52px;
                    margin-bottom: 6px;
                }

                .pic-pic {
                    display: flex;
                    width: 85px;
                    height: 85px;
                    border-radius: 15px;
                }
            }

            .msg-area {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: flex-end;
                width: 180px;
                height: 100%;
                overflow: hidden;
                flex-shrink: 0;

                .msg-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 180px;
                    gap: 40px;

                    .msg-col {
                        display: flex;
                        flex-direction: column;
                        // width: 50%;
                        flex: 1;
                    }

                    .msg-top {
                        color: #000;
                        font-family: "PingFang SC";
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14px;
                        letter-spacing: 0.52px;
                        height: 14px;
                        margin-bottom: 6px;
                    }

                    .msg-bottom {
                        color: #000;
                        font-family: "PingFang SC";
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14px;
                        letter-spacing: 0.52px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .addr-top {
                    // margin-top: 28px;
                    display: flex;
                    align-items: center;
                    color: #6F6B66;
                    font-family: "PingFang SC";
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0.52px;
                    height: 14px;
                    margin-bottom: 6px;
                    gap: 6px;
                }

                .addr-bottom {
                    width: 180px;
                    color: #000;
                    font-family: "PingFang SC";
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0.52px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .des-bottom {
                    width: 500px;
                    color: #000;
                    font-family: "PingFang SC";
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0.52px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .discr-area {
                display: flex;
                flex-direction: column;
                height: 100%;
                flex: 1 1 auto;

                .discr-info {
                    color: #000;
                    font-family: "PingFang SC";
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0.52px;
                    height: 14px;
                    margin-bottom: 6px;
                }

                .discr-discr {
                    display: flex;
                    flex: 1 1 auto;
                    color: #000;
                    font-family: "PingFang SC";
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.52px;
                    white-space: warp;
                    overflow: hidden;
                    word-break: normal;
                }
            }
        }

        .change-btn {
            // position: absolute;
            // top: 24px;
            // right: 24px;
            width: 120px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFF;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            border-radius: 100px;
            border: 1px solid #1890FF;
            background: #1890FF;
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
            margin: auto 0;
            cursor: pointer;
            flex-shrink: 0;
        }
    }

    .statistic-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 24px;
        gap: 10px;
        border-radius: 8px;
        background: #F7F8FA;

        .statistic-data-info {
            display: flex;
            color: #000;
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .cards {
            display: flex;
            width: 100%;

            .category-area {
                display: flex;
                flex-direction: column;
                width: 124px;
                height: 100%;
                overflow: auto;
                background-color: #FFF;
                border-radius: 8px;
                margin-right: 10px;
                padding: 4px 0;

                .category-item {
                    display: flex;
                    width: 100%;
                    padding: 7px 12px;
                    flex-shrink: 0;
                    color: #1D2129;
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    user-select: none;

                    &:hover {
                        color: #3E7BFA;
                    }

                    &-active {
                        color: #3E7BFA !important;
                        background: #D9ECFF !important;
                    }
                }
            }

            .cards-chart-area {
                display: flex;
                height: 310px;
                flex: 1 1 auto;
            }

            .chart {
                position: relative;
                height: 310px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                flex-shrink: 0;
                border-radius: 8px;
                background: #FFF;
                // padding: 26px 11px;

                .chart-range-picker {
                    position: absolute;
                    top: 24px;
                    right: 24px;
                }
            }

            .statistic-data {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .statistic-data-row {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    padding: 0 10px;

                    .statistic-data-block {
                        display: flex;
                        flex-direction: column;
                        width: 250px;
                        padding: 26px 11px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                        flex-shrink: 0;
                        border-radius: 8px;
                        background: #FFF;

                        .statistic-data-first {
                            color: #000;
                            font-family: "PingFang SC";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: 0.64px;
                        }

                        .statistic-data-second {
                            color: #000;
                            font-family: "PingFang SC";
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: 0.96px;
                        }

                        .statistic-data-third {
                            display: flex;
                            gap: 65px;

                            span {
                                color: #888;
                                font-family: "PingFang SC";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 0.64px;
                            }
                        }
                    }
                }
            }


        }

    }

}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;